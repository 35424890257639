import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import { default as Old2005BlogPage, doNothingAnchor } from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—November 2005" />
	<Old2005BlogPage>
		<h2>November 2005</h2>







		<h3 className="blogdate">
		Wednesday, November 30, 2005
		</h3>






		<h4 className="blogitemtitle">
		Join my mailing list!
		</h4>





			<div className="blogitembody">
			Now you can receive occasional emails with information such as: Where am I? What am I doing? What's new? Where and when are my upcoming performances?<br /><br />Don't worry, I too am not fond of continual spam in my inbox, and I don't want my emails to be spam in your inbox. So I will use tasteful discretion in the frequency and scope of my emails!<br /><br />You can send an email to <a href="/" onClick={doNothingAnchor}>mailman@jesserivest.com</a>, from the address you wish to have added or removed, the subject must be ADD or REMOVE, and the message text will be disregarded.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 6:42 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Friday, November 11, 2005
		</h3>






		<h4 className="blogitemtitle">
		Networking
		</h4>





			<div className="blogitembody">
			<p>It is once again time for me to promote some friends and acquaintances of the musical variety in Western Canada!</p><ol><li>Lester Quitzau:  <a href="http://www.lesterq.com/">http://www.lesterq.com/</a> :  I believe Lester is touring again with Tri-Continental, if you don't see him live then you are silly!  Honestly!</li><li>Sillan and Young:  <a href="http://www.sillanyoung.com/">http://www.sillanyoung.com/</a> :  Aaron Young is a guitar delight, and you will love Johanna's voice, and the beautiful songs they have written!  Check their website for dates, or just have a listen to their CD!</li><li>Rebecca Wittwer:  <a href="/" onClick={doNothingAnchor}>http://www.rebecca-syndrome.ca/</a> :  When I hear the songs that Rebecca has posted on her site, my eyes leak.  Her voice is beautiful, and hearing it reminds me of some great friendships that I, in travelling to Australia, have put thousands of kilometers between.  I look forward to hearing her CD, and you should see her live, you will be stunned!</li><li>Dave Simpson:  <a href="/" onClick={doNothingAnchor}>http://www.davesimpsonmusic.com/</a> : Dave just finished recording a new CD, and I really look forward to him releasing it!  I believe Josh Gwilliam of Music Centre Canada recorded it, so it will sound very pure and excellent!  Please check out Dave's website and support him, check his journal, he is doing cool things!</li></ol>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 1:04 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Thursday, November 10, 2005
		</h3>






		<h4 className="blogitemtitle">
		Live on ABC Radio Capricornia
		</h4>





			<div className="blogitembody">
			<strong>When: Wednesday, November 16, 2005, between 9:30 and 10:00am (AEST, same as Brisbane time)</strong><br /><br />This will be my first time on radio! Craig Zonca hosts his show, Capricornia Mornings, every weekday between 9:00am and 11:00am, and has offered to interview me during a live broadcast. You can tune in to 837 AM if you live in Rockhampton, or visit <a href="http://abc.net.au/centralqld">http://abc.net.au/centralqld</a> for other boadcast frequencies in the Capricorn region. Unfortunately, ABC Radio Capricornia does not broadcast over the internet, however Craig has offered to record the interview for me!
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 6:42 PM &nbsp;
			</div>











		<h4 className="blogitemtitle">
		What's Happening in Rockhampton?
		</h4>





			<div className="blogitembody">
			<ul><li>Wednesday nights at O'Dowd's Irish Pub continue!</li><li>Nov 13 at the Allenstown</li><li>Nov 16 live on ABC Radio Capricornia!</li><li>Nov 17 at the Criterion (Newsroom)</li><li>Nov 25 at the Glenmore (Restaurant)</li><li>Dec 4 at the Allenstown</li><li>Dec 9 at the Glenmore (Restaurant)</li><li>Dec 10... leaving Rockhampton...</li></ul>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 6:41 PM &nbsp;
			</div>











		<h4 className="blogitemtitle">
		Quick Pick:  2 Favorite Things about Rockhampton
		</h4>





			<div className="blogitembody">
			<ol><li>It's near-tropical here, I love to watch the big bats play (or is it fight?) at night! They rest upside down in the trees until another bat unsettles them, then they take a quick flight and find another resting spot. They remind me, as they climb through branches, of gorillas, only smaller (and upside down).</li><li>The old architecture and history of the buildings in the central business district. I believe that, originally, Rockhampton was the intended capitol city of Queensland. Many of the buildings were designed and built with lots of money at the end of the 19th century. The Customs House is the most incredible, on Quay Street, opposite the Fitzroy River!</li></ol>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 6:40 PM &nbsp;
			</div>







		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
